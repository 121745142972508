<template>

  <b-button-group>
    <b-button variant="outline-primary" class="bg-light" size="sm" @click="clickFilter()">
      <feather-icon icon="SlidersIcon" class="mr-50" />
      <span class="align-middle">검진필터 서비스</span>
    </b-button>
    <b-button variant="outline-primary" class="bg-light" size="sm" @click="click2d()">
      <feather-icon icon="MapIcon" class="mr-50" />
      <span class="align-middle">2D 지도 보기</span>
    </b-button>

    <b-button variant="primary" size="sm" @click="click3d()">
      <feather-icon icon="GlobeIcon" class="mr-50" />
      <span class="align-middle">3D 지도 보기</span>
    </b-button>
  </b-button-group>
</template>
<script>
import { BButtonGroup, BButton } from 'bootstrap-vue';

export default {
  components: {
    BButtonGroup, BButton,
  },
  props: {
    current: String,
  },
  methods: {
    clickFilter() {
      gis.search.gotoFilter();
    },
    click2d() {
      gis.search.goto2DMap();
    },
    click3d() {
      location.reload();
    }

  }
}
</script>

<style scoped>

</style>
